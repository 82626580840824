/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Účast na obřadu"}>
        <SiteHeader />

        <Column className="pb--60 pt--60" name={"f7u5e5u64j"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102" content={"<br>Svatební obřad v Las Vegas<br>"}>
              </Title>

              <Text className="text-box fs--26" style={{"maxWidth":1000}} content={"Protože u nás v České republice nemůžeme vstoupit do manželství, <br>ale pouze se registrovat jako partneři, rozhodli jsme se vzít v Las Vegas. <br>Tak v celém západním světě, a brzy snad i u nás budeme manželé. <br><br>Nechceme Vás ale nutit absolvovat více než 9 000 km dlouhou cestu do Ameriky, tak jsme pro Vás připravili možnost sledovat náš obřad online. <br>"}>
              </Text>

              <Text className="text-box" content={"<br>"}>
              </Text>

              <ContactForm name={"li7lvhy8b3i"} action={"contact"} fields={[{"name":"E-mail pro zaslání odkazu na živý přenos","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"zeg3b9fnrr"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32019/640cb9b2e17a48a499b9b3fcb0611fcf_e=0x0x1585x792_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32019/640cb9b2e17a48a499b9b3fcb0611fcf_e=0x0x1585x792_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32019/640cb9b2e17a48a499b9b3fcb0611fcf_e=0x0x1585x792_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32019/640cb9b2e17a48a499b9b3fcb0611fcf_e=0x0x1585x792_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32019/640cb9b2e17a48a499b9b3fcb0611fcf_e=0x0x1585x792_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}